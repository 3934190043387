.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 1px;
}

.search-input {
  border: none;
  outline: none;
  border-radius: 1px;
  padding: 1rem 0 1rem 1.5rem;
  font-size: 16px;
  flex: 1;

  background: transparent; /* New */
}

.cancel-button {
  cursor: pointer;
  margin-left: 10px;
  font-size: 20px;

  color: #6d8090; /* New */
  font-size: 1.7rem; /* New */
  font-weight: 400; /* New */
}
