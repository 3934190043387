/* DynamicTable.css */

.DynamicTable {
  text-align: center;
  font-family: Arial, sans-serif;
  /* padding: 20px; New */
  margin-top: 20px;
  /* width: 90%; */
}

.table-container {
  overflow-y: auto;
  max-height: 200px;
  position: relative;
}

.table-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  /*border: 2px solid #ddd;*/
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px; /* Align header cells with data cells */

  text-align: left; /* New */
  font-weight: 400; /* New */
}

/* New */
.custom-table th:first-child,
.custom-table td:first-child {
  padding-left: 10px !important;
  text-overflow: ellipsis !important;
}
.custom-table th:last-child,
.custom-table td:last-child {
  padding-right: 3px !important;
}

.custom-table tr {
  height: 75px !important;
  text-overflow: ellipsis !important;
}

/* .custom-table tr th {
  height: 50px !important;
} */

.ellipsis-cell {
  padding-left: 3px !important;
  max-width: 5px !important;
  text-overflow: ellipsis !important;
}

/* Hide scrollbar for Chrome, Safari and Opera  New */
.table-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox  New */
.table-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Responsive styles for smaller screens */
@media (max-width: 600px) {
  .table-container {
    max-height: none;
    overflow-x: scroll;
  }

  .custom-table th,
  .custom-table td {
    font-size: 14px;
  }
}

/* Responsive styles for even smaller screens */
@media (max-width: 400px) {
  .custom-table th,
  .custom-table td {
    font-size: 12px;
  }
}

.table-container {
  overflow-x: auto;
  max-height: 300px; /* You can adjust this value as needed */
  position: relative;
}

.custom-table th {
  position: sticky;
  top: 0;
  background-color: #f0f0f0;
  color: black;
  z-index: 1;
  max-width: 150px; /* Adjust the width as needed for each column */
}

.flex-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 40px; /* Set a height if needed */
  padding: 10px;
}
